<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            placeholder="请输入优惠券名称"
            class="search-input ml-10"
            v-model="form.couponName"
          ></el-input>
          <el-input
            clearable
            placeholder="请输入优惠码"
            class="search-input ml-10"
            v-model="form.qrCode"
          ></el-input>
          <el-date-picker
            class="ml-10"
            v-model="time"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-10"
            @click="getList"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column
              label="商户名称"
              prop="merchantName"
            ></el-table-column>
            <el-table-column
              label="优惠券名称"
              prop="couponName"
            ></el-table-column>
            <el-table-column label="优惠券类型" prop="coupon">
              <template slot-scope="scope">
                <span v-if="(scope.row.coupon == 3)">餐饮券</span>
                <span v-if="(scope.row.coupon == 4)">折扣券</span>
                <span v-if="(scope.row.coupon == 5)">代金券</span>
                <span v-if="(scope.row.coupon == 6)">停车券</span>
              </template>
            </el-table-column>
            <el-table-column label="优惠券码" prop="qrCode"></el-table-column>
            <el-table-column
              label="优惠金额"
              prop="reducedAmount"
            ></el-table-column>
            <el-table-column
              label="使用规则"
            >
             <template slot-scope="scope">
               <span v-if="scope.row.coupon == 5"> {{'满'+scope.row.fullMoney+'减'+scope.row.reducedAmount}}</span>
              <span v-if="scope.row.coupon == 4"> {{scope.row.couponDiscount+'折'}}</span>
               </template> 
             </el-table-column>
            <el-table-column
              label="核销人员"
              prop="offsetPeople"
            ></el-table-column>
            <el-table-column
              label="核销时间"
              prop="offsetTime"
            ></el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="form.pageNum"
              :pageSize="form.pageSize"
              :pageSizes="[10, 20, 30, 50, 100]"
              @changePage="changePage"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { queryCouponVerification } from "@/api/verify_center/verify_records";
export default {
  components: { Pagination },
  data() {
    return {
      // 顶部搜索栏数据
      form: {
        couponName: "", //	string	true	普通参数		优惠券名
        qrCode: "", //	string	true	普通参数		核销码
        startTime: "", //	string	true	普通参数		开始时间
        endTime: "", //	string	true	普通参数		结束时间
        pageNum: 1, //	number	true	普通参数	1
        pageSize: 10, // number	true	普通参数	10
      },
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      total: 0,
      time: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 初始化数据
    getList() {
      if (this.time.length) {
        this.form.startTime = this.time[0];
        this.form.endTime = this.time[1];
      }
      queryCouponVerification(this.form).then((res) => {
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    // 重置搜索关键词
    reset() {
      this.form = {
        couponName: "", //	string	true	普通参数		优惠券名
        qrCode: "", //	string	true	普通参数		核销码
        startTime: "", //	string	true	普通参数		开始时间
        endTime: "", //	string	true	普通参数		结束时间
        pageNum: 1, //	number	true	普通参数	1
        pageSize: 10, // number	true	普通参数	10
      };
      this.time = [];
      this.getList();
    },
    changePage(type) {
      this.form.pageSize = type.size;
      this.getList();
    },
    syncPageData(data) {
      this.form.pageNum = data.num;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
